import type {
  ISortingConfigItem,
  ISortOrder,
} from "@magnit/unit-catalog/src/unit-catalog.types";
import {
  CatalogBFFSortV2Order,
  CatalogBFFSortV2Type,
  type ICatalogBFFSortV2,
} from "~/typings/api/";

export const NEW_PRODUCTS_LIMIT = 12;
export const PRODUCTS_LIMIT = 36;
export const BANNER_POSITION_INDEX = 4;
export const SORT_DISCOUNT = "discount";
export const SORT_DISCOUNT_PERCENTAGE = "discountPercentage";

export const LABEL_CATALOG_MAIN_PAGE = "Назад";

export const DEFAULT_SORT_ORDER: ISortOrder = "desc";
export const DEFAULT_SORT_BFF_GOOD: ICatalogBFFSortV2 = {
  order: CatalogBFFSortV2Order.desc,
  type: CatalogBFFSortV2Type.popularity,
};

export const goodsSortingConfigDesktop: ISortingConfigItem[] = [];
export const goodsSortingConfigMobile: ISortingConfigItem[] = [
  {
    title: "По популярности",
    value: CatalogBFFSortV2Type.popularity,
    order: CatalogBFFSortV2Order.desc,
  },
  {
    title: "Высокий рейтинг",
    value: CatalogBFFSortV2Type.rating,
    order: CatalogBFFSortV2Order.desc,
  },
  {
    title: "Сначала дешевле",
    value: CatalogBFFSortV2Type.price,
    order: CatalogBFFSortV2Order.asc,
  },
  {
    title: "Сначала дороже",
    value: CatalogBFFSortV2Type.price,
    order: CatalogBFFSortV2Order.desc,
  },
];
